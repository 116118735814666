import Experience from '../experience.js'
import * as THREE from 'three'

export default class Heron

{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.resource = this.resources.items.heronModel
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Heron')
        }

        this.setModel()
        
        //console.log(this)
        this.setAnimation()


    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.75, 0.75,0.75)
        this.model.position.set(0, 0,0)
        this.scene.add(this.model)
        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
            }
        })
    }


    setAnimation()
    {
        this.animation = {}
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        console.log(this.animation.mixer)
        //this.animation.action = this.animation.mixer.clipAction(this.resource.animations[0])
        //this.animation.action.play()
        console.log( this.resource.animations)
        this.animation.actions = {}
        this.animation.actions.Scene = this.animation.mixer.clipAction(this.resource.animations[0])
        //this.animation.actions.current = this.animation.mixer.clipAction(this.resource.animations[0])
        this.animation.actions.Scene.setLoop(2,1);
        this.animation.actions.Scene.clampWhenFinished = true;
        this.animation.actions.Scene.timeScale = 0.5;
        console.log(this.animation.actions.Scene)


        console.log()
       // this.animation.actions.current = this.animation.actions.Scene

        
        //this.animation.actions.current.play()
        this.animation.play = (name) =>
            {
                console.log(name)
                console.log(this.animation.actions['Scene'])
                console.log(this.animation.actions[name])
                const newAction = this.animation.actions[name]
                //const oldAction = this.animation.actions.current

                //newAction.reset()
                newAction.play()
               
                //newAction.crossFadeFrom(oldAction, 1)
                
                 //this.animation.actions.current = newAction
            }


                // Debug
        if(this.debug.active)
            {
                const debugObject = {
                    playscene: () => { this.animation.play('Scene') },                    
                  //  playcurrent: () => { this.animation.play('current') }
                }
                this.debugFolder.add(debugObject, 'playscene')
              this.debugFolder.add(debugObject, 'playcurrent')
     
            }
    }

    update()
    {
        this.animation.mixer.update(this.time.delta * 0.001)
      //  this.model.rotation.y  += .001 * this.experience.time.delta
        // this.scene.rotation.y  += .001 * this.experience.time.delta
    }
}