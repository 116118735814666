
import './style.css'
import Experience from './experience/experience.js'
 
let spin =  document.getElementById("spinner-cont")
console.log(spin)
spin.style.display="flex";


//hide call to action
console.log(document.getElementById("Sec2"))
document.getElementById("Sec2").style.display= 'none';


const experience = new Experience(document.querySelector('canvas.webgl'))
console.log(experience.ready)




document.getElementById("transform").addEventListener("click", loadanimation)


function loadanimation() {
    console.log("play animation ", experience.world.heron.animation)
    experience.world.heron.animation.actions.Scene.
    play()

   console.log(document.getElementById("Sec1"))
   document.getElementById("Sec1").style.display= 'none';
   console.log(document.getElementById("Sec2"))
   document.getElementById("Sec2").style.display= 'flex';
}

console.log();


