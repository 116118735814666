import * as THREE from 'three'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Resources from './Utils/Resources.js'
import Debug from './Utils/Debug.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import sources from './sources.js'
import World from './World/World.js'


let instance = null

export default class Experience
{
    constructor(canvas) {

        if (instance) {
            // if something is instaciate after pass trought construction 
            console.log('b')
           
             //  console.log(this.resources.loaded) 
             
             return instance
           
        }

             instance = this
            console.log('a')
           
         // document.getElementById('canvas').innerHTML('<a>a</a>')


            //global experience so we can acces by window.experience
        window.experience = this
        console.log('here starts a great experience');
        this.ready= 0;
        this.cursor = {}
        this.cursor.x = 0
        this.cursor.y = 0

        //here we instanciate every element is going to be used in the xperinece
        //setup 
        this.canvas = document.querySelector('canvas.webgl')
        this.resources = new Resources(sources)
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene
        this.camera = new Camera()
        this.world = new World()
        console.log(this.world.resources.sources.length) 

        if(this.world.resources.sources.length == 6 ){
            this.renderer = new Renderer()
            
            let spin =  document.getElementById("spinner-cont")
            console.log(spin)
            spin.style.display="none";
            this.ready = 1;
        }


        // this time will Load Every second and work as a tick function
        this.time.on('tick', () =>
        {
            this.update()
        })
        // this triger will display camera & world Update
        this.sizes.on('resize', () => {    
           this.resize()
        } )


    }

    resize()
    {
         this.camera.resize()
        this.renderer.resize()
        if(this.sizes.width > this.sizes.height){
            //console.log("landscape")
        }
        else{
            //console.log("portrail")
                    //this.world.scene.z =         
           // console.log(this.world.scene.position)
        }
     
       
    }

    Mouse()
    {
        
       

      
    }
    update() {

        this.camera.update()
        this.world.update()
        this.renderer.update()

        //console.log(scrollY);
        //console.log(this.camera.scene.position.y )
          //times
         
        //  console.log(this.scene.rotation.y += 0.01 * this.time.delta)
            //console.log(this.world rotation.y += 0.01 * this.time.delta)

      

        const parallaxX = this.cursor.x
        const parallaxY = - this.cursor.y

       // console.log(this.cursor.x)
        this.camera.scene.position.x = parallaxX
        this.camera.scene.position.y = parallaxY
        this.camera.scene.position.y =  scrollY / 125

          

    }

    destroy()
    {
        this.sizes.off('resize')
        this.time.off('tick')
    
        // Traverse the whole scene
    // Traverse the whole scene
    this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })
        this.camera.controls.dispose()
        this.renderer.instance.dispose()
        if(this.debug.active)
            this.debug.ui.destroy()
    }
    

}

