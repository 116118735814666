import Experience from '../experience.js'
import  * as THREE from 'three'

export default class Environment
{

    constructor()
    {
       
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.scene = this.experience.scene
        this.debug = this.experience.debug
        
        const fog = new THREE.Fog('#262837', 1, 15)
        this.scene.fog = fog
        

        if(this.debug.active)
            {
                this.debugFolder = this.debug.ui.addFolder('environment')
            }

             // Setup
             this.setSunLight()
             this.setEnvironmentMap()
    }
    setSunLight()
    {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 4)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(-5, -5, - 5)
        this.scene.add(this.sunLight)

// Debug
if(this.debug.active)
    {
        this.debugFolder
            .add(this.sunLight, 'intensity')
            .name('sunLightIntensity')
            .min(0)
            .max(10)
            .step(0.001)
        
        this.debugFolder
            .add(this.sunLight.position, 'x')
            .name('sunLightX')
            .min(- 5)
            .max(5)
            .step(0.001)
        
        this.debugFolder
            .add(this.sunLight.position, 'y')
            .name('sunLightY')
            .min(- 5)
            .max(5)
            .step(0.001)
        
        this.debugFolder
            .add(this.sunLight.position, 'z')
            .name('sunLightZ')
            .min(- 5)
            .max(5)
            .step(0.001)
    }


    }
    setEnvironmentMap()
    {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.4
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.colorSpace = THREE.SRGBColorSpace
        
        this.scene.environment = this.environmentMap.texture
        this.environmentMap.updateMaterials = () =>
            {
                this.scene.traverse((child) =>
                {
                    if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                    {
                        child.material.envMap = this.environmentMap.texture
                        child.material.envMapIntensity = this.environmentMap.intensity
                        child.material.needsUpdate = true
                    }
                })
            }
            this.environmentMap.updateMaterials()
          // Debug
          if(this.debug.active)
            {
                this.debugFolder
                    .add(this.environmentMap, 'intensity')
                    .name('envMapIntensity')
                    .min(0)
                    .max(4)
                    .step(0.001)
                    .onChange(this.environmentMap.updateMaterials)
            }
    }
}