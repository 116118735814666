import Experience from '../experience.js'
import * as THREE from 'three'

export default class Particles
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

       //
        /**** PARTICLES
         * 
         */const parameters = {
            materialColor: '#ffeded'
        }

        const sectionMeshes = [ this.mesh1, this.mesh2, this.mesh3 ]
        const objectsDistance = 10

        const particlesCount = 1000
        const positions = new Float32Array(particlesCount * 5)

        for(let i = 0; i < particlesCount; i++)
        {
            positions[i * 3 + 0] = (Math.random() - 0.5) * 30
            positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * sectionMeshes.length
            positions[i * 3 + 2] = (Math.random() - 0.5) * 10
        }


        const particlesGeometry = new THREE.BufferGeometry()
        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        // Material
        const particlesMaterial = new THREE.PointsMaterial({
            color: parameters.materialColor,
            sizeAttenuation: true,
            size: 0.03
        })

        // Points
        this.particles = new THREE.Points(particlesGeometry, particlesMaterial)


        this.setparticles()
        

            }  
   
           
            update()
            {
                //this.animation.mixer.update(this.time.delta * 0.001)
                //this.model.rotation.y  += .001 * this.experience.time.delta
                //console.log(this.particles.position.y += .0001 * this.experience.time.delta );
                //console.log(this.particles.position.y += .0005)
                
            }


            setparticles(){
                this.scene.add(this.particles)
            }
  
}