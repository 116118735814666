import * as THREE from 'three'
import Experience from "./experience"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'



export default class Camera
{
    constructor() {

        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        const cursor = {}
        cursor.x = 0
        cursor.y = 0


        this.setInstance()
        this.setControls()
    }
     setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)
        this.instance.position.set(0, 0, 8)
        this.scene.add(this.instance)
        this.set_aspect()
     
    }

     setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    set_aspect(){
        if(this.sizes.width > this.sizes.height){
           // console.log("landscape")
            this.instance.position.set(0, 0, 8)    
           // console.log(this.experience.scene.position)
        }
        else{
           // console.log("portrail")
           this.instance.position.set(5, 3, 12)    
            //console.log(this.experience.scene.position)
        }
    
    }
    
    resize()
    {

   
       
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
        this.set_aspect()
    }
    
     update()
    {
        
      
        this.controls.update()
    }


}



window.addEventListener('mousemove', (event) =>
    {

        //this.update(cursor)
         parent.experience.cursor.x = event.clientX / parent.experience.sizes.width
        parent.experience.cursor.y = event.clientY / parent.experience.sizes.height
    
        //console.log(  )
     //   console.log(parent.experience)
       // console.log( event.clientX)
    })

