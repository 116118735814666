import Experience from '../experience.js'
import Environment from './Environment.js'
import * as THREE from 'three'

import Floor from './Floor.js'
import Fox from './Fox.js'
import Bulletbot from './Bulletbot.js'
import Heron from './Heron.js'

import Mesh1 from './mesh1.js'
import { deflate } from 'three/examples/jsm/libs/fflate.module.js'
import Particles from './Particles.js'
export default class World
{
   
    constructor()
    {
          this.experience = new Experience()
          this.scene = this.experience.scene
          this.resources = this.experience.resources
        
          console.log(this.resources)
  
      // Wait for resources
      this.resources.on('ready', () =>
        {
            // Setup
                      
                        //0this.floor = new Floor()
                        //this.fox = new Fox()
                         this.environment = new Environment()
                         //this.bulletbot = new Bulletbot()
                         this.heron = new Heron()
                         this.particles = new Particles()
                            //this.mesh1 = new Mesh1()
                            //this.mesh2 = new Mesh1()
                           // this.mesh3 = new Mesh1()
                            this.loadparticles()
       
                        })

        this.setSunLight()
        // Test mesh
        const testMesh = new THREE.Mesh(
            new THREE.BoxGeometry(1, 1, 1),
            new THREE.MeshStandardMaterial()
        )

   
       // this.scene.add(testMesh)

     //  this.scene.add(mesh1, mesh2, mesh3)


        
    }


    setSunLight()
    {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 4)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(3, 3, - 2.25)
        this.scene.add(this.sunLight)
    }

    update()
    {
      
         if(this.particles)
            this.particles.update()
      
        if(this.fox)
            this.fox.update()
         

        if(this.bulletbot)
            this.bulletbot.update()
        
        if(this.heron)
            this.heron.update()


    }
    loadparticles(){
        
    }
}