export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    //floor or ground
    {
        name: 'grassColorTexture',
        type: 'texture',
        path: 'textures/dirt/color.jpg'
    },
    {
        name: 'grassNormalTexture',
        type: 'texture',
        path: 'textures/dirt/normal.jpg'
    },

    // fox
    {
        name: 'foxModel',
        type: 'gltfModel',
        path: 'models/Fox/glTF/Fox.gltf'
    },

    //Bulletbot
        {
            name: 'bulletbotModel',
            type: 'gltfModel',
            path: 'models/Bulletbot/bulletbot.gltf'
        },

        //Bulletbot
            {
                name: 'heronModel',
                type: 'gltfModel',
                path: 'models/Heron/blendsceneheron5.gltf'
            }
]